<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <button
          class="btn w-100 text-start"
          v-on:click="filterCountry = null"
          :class="filterCountry === null ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("companies.viewAll") }}
        </button>
        <button
          class="btn w-100 text-start"
          :class="filterCountry === c.id ? 'btn-primary' : 'btn-simple'"
          v-for="c in activeCountries.filter(
            (e) => companyCountries.indexOf(e.id) > -1
          )"
          :key="c.id"
          v-on:click="filterCountry = c.id"
        >
          {{ c.name }}
        </button>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10">
      <h2>
        {{ $t("companies.companies") }}
      </h2>
      <one-by-one-table
        v-model:rows="rows"
        :items="
          companies === undefined
            ? undefined
            : companies.filter(
                (e) =>
                  e.address?.country_id === filterCountry ||
                  filterCountry === null
              )
        "
        type="company"
        :show-edit="true"
        v-on:edit="showEditCompanyModal"
        v-on:addbtn="showAddCompanyModal"
        v-on:delete="showDeleteCompanyModal"
      />
    </div>
  </div>
  <div
    ref="addCompanyModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              $t(`companies.company`, {
                expr: editCompany ? "Edit" : "Add new",
              })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-3 mt-2">
              <button
                class="btn w-100 d-block text-start"
                v-on:click="editCategory = 'home'"
                :class="{
                  'btn-primary': editCategory === 'home',
                  'btn-simple': editCategory !== 'home',
                }"
              >
                Basics
              </button>
              <button
                class="btn w-100 d-block text-start"
                v-on:click="editCategory = 'taxes'"
                :class="{
                  'btn-primary': editCategory === 'taxes',
                  'btn-simple': editCategory !== 'taxes',
                }"
              >
                Taxes
              </button>
            </div>
            <div class="col-9 mt-2 text-start custom-input">
              <div v-if="editCategory === 'home'">
                <div class="text-start m-2 custom-input">
                  <div class="row">
                    <div
                      class="col-6 mb-3 whitebg-input"
                      v-if="activeCountry?.is_eu"
                    >
                      <label class="form-label bold-12 text-gray">
                        {{ $t("companies.euTaxNumber") }}
                      </label>
                      <input
                        class="form-control"
                        v-model="newCompany.eu_tax_code"
                        v-on:change="searchBusiness"
                      />
                    </div>
                    <div class="col-6 mb-3 whitebg-input">
                      <label class="form-label bold-12 text-gray">
                        {{ $t("companies.localTaxNumber") }}
                      </label>
                      <input
                        class="form-control"
                        v-model="newCompany.tax_code"
                      />
                    </div>
                    <div
                      class="mb-3 whitebg-input"
                      :class="activeCountry?.is_eu ? 'col-12' : 'col-6'"
                    >
                      <label class="form-label bold-12 text-gray">
                        {{ $t("addNewContactsModal.name2") }}
                      </label>
                      <input class="form-control" v-model="newCompany.name" />
                    </div>
                  </div>
                  <div class="mb-3 whitebg-input">
                    <label class="form-label bold-12 text-gray">{{
                      $t("address.country")
                    }}</label>
                    <v-select
                      :options="activeCountries"
                      v-model="newCompany.country_id"
                      v-on:change="editAddress('country_id')"
                      label="name"
                      :placeholder="$t(`address.selectCountry`)"
                      :reduce="(c) => c.id"
                      append-to-body
                    />
                  </div>
                  <div class="row mb-3 custom-inpu">
                    <div class="col-4 whitebg-input">
                      <label class="form-label bold-12 text-gray">{{
                        $t("companies.postCode")
                      }}</label>
                      <input
                        type="text"
                        v-model="newCompany.post_code"
                        v-on:change="editAddress('post_code')"
                        class="form-control"
                      />
                    </div>
                    <div class="col whitebg-input">
                      <label class="form-label bold-12 text-gray">{{
                        $t("address.city")
                      }}</label>
                      <input
                        type="text"
                        v-model="newCompany.city"
                        v-on:change="editAddress('city')"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="row mb-3 whitebg-input">
                    <div class="col">
                      <label class="form-label bold-12 text-gray">{{
                        $t("address.street")
                      }}</label>
                      <input
                        type="text"
                        v-model="newCompany.street"
                        v-on:change="editAddress('street')"
                        class="form-control"
                      />
                    </div>
                    <div class="col-5 whitebg-input">
                      <label class="form-label bold-12 text-gray">{{
                        $t("address.house")
                      }}</label>
                      <input
                        type="text"
                        v-model="newCompany.house"
                        v-on:change="editAddress('house')"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="editCategory === 'taxes'">
                <div v-if="activeCountry">
                  <h3 class="mb-3 bold-19">{{ activeCountry.name }}</h3>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16">Normal VAT</span>
                    </span>
                    <input
                      type="radio"
                      v-model="countryType[activeCountry.id].tax_option"
                      :value="1"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16">No VAT collection</span>
                    </span>
                    <input
                      type="radio"
                      v-model="countryType[activeCountry.id].tax_option"
                      :value="99"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div v-if="activeCountry?.is_eu">
                  <h3 class="mb-3 bold-19">EU</h3>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16">OSS</span>
                      <span
                        >Charge VAT at the rate applicable in the customer's
                        shipping country under the One-Stop Shop (OSS)
                        scheme</span
                      >
                    </span>
                    <input type="radio" v-model="euType" :value="2" /><span
                      class="checkmark"
                    ></span>
                  </label>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16"
                        >Micro business exemption</span
                      >
                      <span
                        >charge your home country's VAT rates on all the sales
                        within the EU up to 10.000 EUR</span
                      >
                    </span>
                    <input type="radio" v-model="euType" :value="3" /><span
                      class="checkmark"
                    ></span>
                  </label>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16"
                        >Unique TAX registration</span
                      >
                      <span
                        >Charge VAT at the rate applicable in the customer's
                        shipping country and hold tax registration for each EU
                        country</span
                      >
                    </span>
                    <input type="radio" v-model="euType" :value="1" /><span
                      class="checkmark"
                    ></span>
                  </label>
                </div>
                <div v-else>
                  <h3 class="mb-3 bold-19">EU export</h3>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16">IOSS</span>
                      <span
                        >Charge VAT at the rate applicable in the customer's
                        shipping country under the Import One-Stop Shop (IOSS)
                        scheme</span
                      >
                    </span>
                    <input type="radio" v-model="euType" :value="2" /><span
                      class="checkmark"
                    ></span>
                  </label>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16">No VAT collection</span>
                      <span>Sell without VAT</span>
                    </span>
                    <input type="radio" v-model="euType" :value="3" /><span
                      class="checkmark"
                    ></span>
                  </label>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16"
                        >Unique TAX registration</span
                      >
                      <span
                        >Charge VAT at the rate applicable in the customer's
                        shipping country and hold tax registration for each EU
                        country</span
                      >
                    </span>
                    <input type="radio" v-model="euType" :value="1" /><span
                      class="checkmark"
                    ></span>
                  </label>
                </div>

                <div v-if="activeCountry?.is_eu && ukId > 0">
                  <h3 class="mb-1 bold-19">UK export</h3>
                  <p class="regular-14 mb-3">
                    When selling to consumers (B2C) from the EU to the UK, you
                    must register for a UK VAT registration number. Orders worth
                    less than £135 are subject to 20% VAT at the point of sale.
                    Orders worth over £135 are subject to import VAT rules.
                  </p>
                  <div
                    class="col-6 mb-3 mx-auto whitebg-input"
                    v-if="countryType[ukId].tax_option !== 99"
                  >
                    <label class="form-label bold-12 text-gray">
                      {{ $t("companies.localTaxNumber") }}
                    </label>
                    <input
                      class="form-control"
                      v-model="countryType[ukId].tax_number"
                    />
                  </div>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16">£135 limit</span>
                      <span
                        >Collect VAT only sales equal to or less than 135
                        GBP</span
                      >
                    </span>
                    <input
                      type="radio"
                      v-model="countryType[ukId].tax_option"
                      :value="6"
                    /><span class="checkmark"></span>
                  </label>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16"
                        >Collect VAT and duties</span
                      >
                      <span
                        >Charge the correct VAT and duty rates on all the
                        sales</span
                      >
                    </span>
                    <input
                      type="radio"
                      v-model="countryType[ukId].tax_option"
                      :value="4"
                    /><span class="checkmark"></span>
                  </label>
                  <label class="cstm-check d-flex gap-2 mb-3">
                    <span class="text-black mx-2 regular-14">
                      <span class="d-block bold-16">No VAT collection</span>
                      <span>Sell without VAT</span>
                    </span>
                    <input
                      type="radio"
                      v-model="countryType[ukId].tax_option"
                      :value="99"
                    /><span class="checkmark"></span>
                  </label>
                </div>
                <div>
                  <h3 class="mb-3 bold-19">Export</h3>
                  <div class="row mb-3 bold-16">
                    <div class="col-3">Country</div>
                    <div class="col-3 text-center">TAX collection</div>
                    <div class="col-3 text-center">Lower prices with TAX</div>
                    <div class="col-3 text-center">No TAX collection</div>
                  </div>
                  <div
                    v-for="country in exportCountries"
                    :key="country.id"
                    class="row mb-1"
                  >
                    <div class="col-3">
                      {{ country.name }}
                    </div>
                    <div class="col-3 justify-content-center d-flex">
                      <label class="cstm-check">
                        <input
                          type="radio"
                          v-model="countryType[country.id].tax_option"
                          :value="4"
                        /><span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="col-3 justify-content-center d-flex">
                      <label class="cstm-check">
                        <input
                          type="radio"
                          v-model="countryType[country.id].tax_option"
                          :value="5"
                        /><span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="col-3 justify-content-center d-flex">
                      <label class="cstm-check">
                        <input
                          type="radio"
                          v-model="countryType[country.id].tax_option"
                          :value="99"
                        /><span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="saveCompany"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
            v-if="editCompany"
          >
            {{ $t("finance.save") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addCompany"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
            v-else
          >
            {{ $t("companies.addNew") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ConfirmModal
    ref="deleteModal"
    :title="$t(`companies.deleteCompany`)"
    :text="`<ul><li>You will delete ${deleteObject?.name}<b></li><li>The warehouses of this company will be removed from the company</li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
    :cancel-button="`No, keep ${deleteObject?.name}`"
    :ok-button="`Yes, delete ${deleteObject?.name}`"
    v-on:success="deleteCompany"
  />
</template>

<script>
import OneByOneTable from "../lists/OneByOneTable";
import http from "../../modules/http";
import { useStore } from "vuex";
import ConfirmModal from "../modals/ConfirmModal";
const bootstrap = require("bootstrap");

export default {
  name: "Companies",
  components: { ConfirmModal, OneByOneTable },
  data() {
    return {
      loadAddBtn: false,
      filterCountry: null,
      deleteObject: {},
      editCompany: false,
      editCategory: "home",
      euType: 1,
      countryType: {},
      newCompany: {
        eu_tax_code: "",
      },
      rows: [
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Tax number",
          key: "tax_code",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "EU Tax number",
          key: "eu_tax_code",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Address",
          key: "address",
          type: "address",
          show: true,
          editable: false,
        },
      ],
      companies: undefined,
      countries: [],
      addWindow: null,
    };
  },
  computed: {
    activeCountries() {
      const store = useStore();
      return store.state.countries;
    },
    activeCountry() {
      return this.countries.find((e) => e.id === this.newCompany.country_id);
    },
    ukId() {
      return this.activeCountries.find((e) => e.code === "UK")?.id;
    },
    exportCountries() {
      if (this.activeCountry?.is_eu) {
        return this.activeCountries.filter(
          (e) =>
            e.id !== this.newCompany.country_id && !e.is_eu && e.code !== "UK"
        );
      }
      return this.activeCountries.filter(
        (e) => e.id !== this.newCompany.country_id && !e.is_eu
      );
    },
    companyCountries() {
      let countries = [];
      if (this.companies === undefined) {
        return [];
      }
      for (const company of this.companies) {
        if (countries.indexOf(company.address.country_id) === -1) {
          countries.push(company.address.country_id);
        }
      }
      return countries;
    },
  },
  mounted() {
    http.fetch("/companies").then((data) => {
      this.companies = data;
    });
  },
  methods: {
    editAddress(prop) {
      if (this.newCompany.id > 0) {
        this.newCompany.address[prop] = this.newCompany[prop];
      }
    },

    addCompany() {
      this.loadAddBtn = true;
      http
        .fetch("/companies", this.newCompany, true)
        .then((data) => {
          this.addWindow.hide();
          this.loadAddBtn = false;
          this.companies.push(data);
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    saveCompany() {
      this.loadAddBtn = true;
      let data = Object.assign({}, this.newCompany);
      delete data.id;

      data.companyTaxSettings = [];
      data.companyTaxSettings.push({
        is_eu: 1,
        tax_option: this.euType ?? null,
      });
      for (const country in this.countryType) {
        data.companyTaxSettings.push({
          country_id: country,
          tax_number: this.countryType[country]?.tax_number ?? null,
          tax_option: this.countryType[country]?.tax_option ?? null,
        });
      }

      http
        .fetch("/companies/" + this.newCompany.id, data, true, "PUT")
        .then(() => {
          this.addWindow.hide();
          this.loadAddBtn = false;
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
    deleteCompany() {
      http
        .fetch("/companies/" + this.deleteObject.id, {}, true, "DELETE")
        .then(() => {
          let i = this.companies.indexOf(this.deleteObject);
          if (i > -1) {
            this.companies.splice(i, 1);
          }
        });
    },
    showDeleteCompanyModal(element) {
      this.deleteObject = element;
      this.$refs.deleteModal.showModal();
    },
    showEditCompanyModal(id) {
      this.editCompany = true;
      let data = this.companies.filter((e) => e.id === id)[0];
      data = Object.assign(data, data.address);

      this.newCompany = data;

      if (this.countries.length === 0) {
        http.fetch("/countries/all").then((data) => {
          this.countries = data;
          this.loadCountryTaxType();
        });
      } else {
        this.loadCountryTaxType();
      }
      this.addWindow = new bootstrap.Modal(this.$refs.addCompanyModal, {
        backdrop: "static",
      });
      this.addWindow.show();
    },
    loadCountryTaxType() {
      for (const country of this.activeCountries) {
        this.countryType[country.id] = {};
      }
      for (const setting of this.newCompany?.companyTaxSettings) {
        if (setting.is_eu) {
          this.euType = setting.tax_option;
        } else {
          this.countryType[setting.country_id].tax_number = setting.tax_number;
          this.countryType[setting.country_id].tax_option = setting.tax_option;
        }
      }
    },
    showAddCompanyModal() {
      this.editCompany = false;
      this.newCompany = {
        eu_tax_code: "",
      };
      if (this.countries.length === 0) {
        http.fetch("/countries/all").then((data) => {
          this.countries = data;
          this.loadCountryTaxType();
        });
      } else {
        this.loadCountryTaxType();
      }
      this.addWindow = new bootstrap.Modal(this.$refs.addCompanyModal, {
        backdrop: "static",
      });
      this.addWindow.show();
    },
    searchBusiness() {
      http
        .fetch("/companies/search", { tax: this.newCompany.eu_tax_code })
        .then((data) => {
          if (data.length > 0) {
            this.newCompany.name = data[0].name;

            if (data[0].address !== undefined) {
              this.newCompany.country_id = this.countries.filter(
                (e) => e.code === data[0].country
              )[0].id;
              this.newCompany.house = data[0].address;
              this.newCompany.city = data[0].city;
              this.newCompany.post_code = data[0].post_code;
              this.newCompany.street = data[0].road;
            } else {
              this.newCompany.house = data[0].companyAddress;
            }
          }
        });
    },
  },
};
</script>
